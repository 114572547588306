import React from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import lgBanner1 from "../../Images/Vitamin-C-pc-copy.jpg";
import lgBanner2 from "../../Images/Aloe-vera.jpg";
import lgBanner4 from "../../Images/sunscreen_pc.jpg";
import lgBanner6 from "../../Images/HANDWASH-WEBSLIDER-DESKTOP.jpg";
import lgBanner7 from "../../Images/Rose-Jam-pc-copy.jpg";
import mbBanner1 from "../../Images/Vitamin-C-Mob-copy.jpg";
import mbBanner2 from "../../Images/Aloe-vera-mobile.jpg";
import mbBanner4 from "../../Images/sunscreen-mb.jpg";
import mbBanner6 from "../../Images/HANDWASH-WEBSLIDER-mOBILE.jpg";
import mbBanner7 from "../../Images/Rose-Jam-mobile-copy.jpg";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const Banner = () => {
  const isDesktop = window.innerWidth > 992;
  const banner01 = isDesktop ? lgBanner1 : mbBanner1;
  const banner02 = isDesktop ? lgBanner2 : mbBanner2;
  const banner04 = isDesktop ? lgBanner4 : mbBanner4;
  const banner06 = isDesktop ? lgBanner6 : mbBanner6;
  const banner07 = isDesktop ? lgBanner7 : mbBanner7;

  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect="fade"
        navigation={isDesktop}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={window.innerWidth < 700 ? false : { dynamicBullets: true }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper h-auto"
      >
        <SwiperSlide className="bg-[#ffffff]">
          <Link to="/shop" className="h-[200px] lg:h-[500px] flex items-center lg:items-start">
            <img
              className="w-full h-auto z-10"
              src={banner01}
              alt="Banner 1"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="eager"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link to="/product-category/shower gel" className="h-[200px] lg:h-[500px] flex items-center lg:items-start">
            <img
              className="w-full h-auto z-10"
              src={banner07}
              alt="Banner 7"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link to="/product-category/sunscreen" className="h-[200px] lg:h-[500px] flex items-center lg:items-start">
            <img
              className="w-full h-auto z-10"
              src={banner04}
              alt="Banner 7"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link to="/product-category/hand%20wash" className="h-[200px] lg:h-[500px] flex items-center lg:items-start">
            <img
              className="w-full h-auto z-10"
              src={banner06}
              alt="Banner 6"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link to="/product-category/face%20wash" className="h-[200px] lg:h-[500px] flex items-center lg:items-start">
            <img
              className="w-full h-auto z-10"
              src={banner02}
              alt="Banner 2"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;

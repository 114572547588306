import React, { Suspense, lazy, useEffect, useState, useMemo } from "react";
import "./App.css";
import { ThemeContext } from "./Contexts/ThemeContext";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import router from "./Routes/Routes";
import useProduct from "./Hooks/useProduct";
import useCart from "./Hooks/useCart";
import useOrder from "./Hooks/useOrder";
import useScroll from "./Hooks/useScroll";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "./firebase.init";
import ReactGA from "react-ga4";

ReactGA.initialize("G-3G0Q3PYC1F");

// Lazy load Product component
const Product = lazy(() => import("./Components/Shared/Product"));

function App() {
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [products, loadingProducts] = useProduct();
  const [cart, setCart] = useCart(products);
  const [orderList, setOrderList] = useOrder();
  const [scrollPosition] = useScroll();
  const [searchText, setSearchText] = useState("");
  const [favProducts, setFavProducts] = useState([]);
  const [summerMakeup, setSummerMakeup] = useState([]);
  const [nudeMakeup, setNudeMakeup] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [allOrder, setAllOrder] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [blogLoading, setBlogLoading] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Earth Beauty & You",
    });
  }, []);

  useEffect(() => {
    // Fetch admin status
    const fetchAdminStatus = async () => {
      setIsAdminLoading(true);
      try {
        let isAdminFromPhone = false;
        let isAdminFromEmail = false;

        if (user?.phoneNumber) {
          const response = await fetch(`https://ebay-backend.vercel.app/users/admin/${user.phoneNumber}`);
          const data = await response.json();
          isAdminFromPhone = data.isAdmin;
        }

        if (user?.email) {
          const response = await fetch(`https://ebay-backend.vercel.app/users/admin1/${user.email}`);
          const data = await response.json();
          isAdminFromEmail = data.isAdmin;
        }

        setIsAdmin(isAdminFromPhone || isAdminFromEmail);
      } catch (error) {
        console.error("Error fetching admin status:", error);
      } finally {
        setIsAdminLoading(false);
      }
    };

    fetchAdminStatus();
  }, [user]);

  useEffect(() => {
    // Fetch initial data
    const fetchInitialData = async () => {
      try {
        const [favProductsRes, ordersRes, blogsRes] = await Promise.all([
          fetch("https://server.earthbeautyandyou.com/getProductsByCategories?name=face wash").then(res => res.json()),
          fetch("https://server.earthbeautyandyou.com/orders").then(res => res.json()),
          fetch("https://server.earthbeautyandyou.com/getBlogs").then(res => res.json()),
        ]);

        setFavProducts(favProductsRes);
        setAllOrder(ordersRes);
        setBlogs(blogsRes);
        setBlogLoading(false); // Assuming blogs are loaded after fetch
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setBlogLoading(false); // Handle loading state in case of error
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    // Search functionality
    if (searchText.length > 2) {
      const result = products?.filter((product) =>
        product.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchedProducts(result);
    }
  }, [searchText, products]);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const contextValue = useMemo(() => ({
    products,
    blogs,
    blogLoading,
    allOrder,
    loading: loadingProducts,
    favProducts,
    summerMakeup,
    nudeMakeup,
    searchedProducts,
    cart,
    setCart,
    orderList,
    setOrderList,
    searchText,
    setSearchText,
    appliedCoupon,
    setAppliedCoupon,
    user,
    isAdmin,
    isAdminLoading,
  }), [
    products,
    blogs,
    blogLoading,
    allOrder,
    loadingProducts,
    favProducts,
    summerMakeup,
    nudeMakeup,
    searchedProducts,
    cart,
    setCart,
    orderList,
    setOrderList,
    searchText,
    appliedCoupon,
    user,
    isAdmin,
    isAdminLoading,
  ]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <RouterProvider router={router}></RouterProvider>

      <svg
        title="Back To Top"
        className={`transition-all cursor-pointer w-10 h-10  duration-300 ease-in-out fixed bottom-[100px] right-8 ${
          scrollPosition > 1000
            ? "opacity-0 lg:opacity-100"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={() => goToTop()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="up-button"
      >
        <path d="M65.4 44.6c.8.8.8 2 0 2.8-.4.4-.9.6-1.4.6s-1-.2-1.4-.6L52 36.8V68c0 1.1-.9 2-2 2s-2-.9-2-2V36.8L37.4 47.4c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14-14 .3-.3c.1 0 .1-.1.2-.1s.1-.1.2-.1.1 0 .2-.1h1c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1l.3.3 13.9 13.9zM95 15v70c0 5.5-4.5 10-10 10H15C9.5 95 5 90.5 5 85V15C5 9.5 9.5 5 15 5h70c5.5 0 10 4.5 10 10zm-4 0c0-3.3-2.7-6-6-6H15c-3.3 0-6 2.7-6 6v70c0 3.3 2.7 6 6 6h70c3.3 0 6-2.7 6-6V15z"></path>
      </svg>

      <Toaster />
    </ThemeContext.Provider>
  );
}

export default App;
